import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import HomeArticleCard from "components/atoms/HomeArticleCard";
import { Article } from "API";

type Props = {
  articles: Article[];
};

export default function HomeArticleCards(props: Props) {
  const { articles } = props;

  const mainArticle = articles[0];
  const subArticles = articles.slice(1, 5);

  // const isMobile = useMediaQuery("(max-width: 700px)");
  const isMobile = true;

  return (
    <>
      <Grid container spacing={3}>
        {mainArticle && isMobile ? (
          <HomeArticleCard article={mainArticle || []} isMainArticle />
        ) : (
          <Grid item xs={6}>
            <HomeArticleCard article={mainArticle || []} isMainArticle />
          </Grid>
        )}
        {isMobile ? (
          <div
            style={{
              padding: "12px",
            }}
          >
            {subArticles && (
              <Grid item xs={6} style={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  {subArticles
                    .filter((article) => article)
                    .map((article: Article, key) => (
                      <Grid item xs={12} key={key}>
                        <HomeArticleCard article={article} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
          </div>
        ) : (
          <>
            {subArticles && (
              <Grid item xs={6} style={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  {subArticles
                    .filter((article) => article)
                    .map((article: Article, key) => (
                      <Grid item xs={6} key={key}>
                        <HomeArticleCard article={article} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}

HomeArticleCards.defaultProps = {
  showCategory: false,
};
