import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Layout from "components/layouts/Common";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import SearchResult from "components/pages/front/SearchResult";
import { NoSsr } from "@material-ui/core";

type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;

  const { listArticlePickups, footers } = pageContext;

  return (
    <>
      <GatsbySeo
        title="検索結果 舵オンライン │  船遊びの情報サイト（舵社）"
        description="舵オンラインは、『Kazi』、『ボート倶楽部』を刊行する舵社が運営する、船遊びに関する情報サイト。ヨット、ボート、ボート釣り、クルージング、ボート免許、カヌーなど、プレジャーボートや海に関するさまざまなニュースをお届けします。"
        openGraph={{
          url: `https://kazi-online.com/search-result`,
          type: "website",
          title: "検索結果 舵オンライン │  船遊びの情報サイト（舵社）",
          description:
            "舵オンライン（KAZI online）は、『Kazi』、『ボート倶楽部』を刊行する舵社が運営する、船遊びに関する情報サイト。ヨット、ボート、ボート釣り、クルージング、ボート免許、カヌーなど、プレジャーボートや海に関するさまざまなニュースをお届けします。",
          site_name: "舵オンライン │ 船遊びの情報サイト",
          images: [
            {
              url:
                "https://kazi-online.com/images/KAZIonline_SHARE_1200×630pix.jpg",
              alt: "舵オンライン │  船遊びの情報サイト（舵社）",
            },
          ],
        }}
      />
      <Helmet>
        <title>検索結果 舵オンライン │ 船遊びの情報サイト（舵社）</title>
      </Helmet>
      <Layout footers={footers}>
        <NoSsr>
          <SearchResult listArticlePickups={listArticlePickups} />
        </NoSsr>
      </Layout>
    </>
  );
}
