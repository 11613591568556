import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import { cardTitle } from "assets/jss/material-kit-react.js";
import Html2Text from "components/atoms/Html2Text";
import { getCfUrl } from "settings/storageSettings";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Article } from "API";
import {
  Box,
  ButtonBase,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import styled from "styled-components";
import { navigate } from "gatsby";
import theme from "theme";
import ResponsiveComponent from "./ResponsiveComponent";

const styles = {
  ...imagesStyles,
  cardTitle: {
    ...cardTitle,
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  root: {
    minHeight: "320px",
    width: "100%",
  },
  descriptionText: {
    height: "2rem",
    textOverflow: "ellipsis",
    lineHeight: 2,
  },
  category: {
    height: "1rem",
  },
  textMuted: {
    color: "#6c757d",
  },
};

const CategoryReact = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  color: ${theme.palette.common.black};
  background-color: ${theme.palette.grey[300]};
`;

type Props = {
  article: Article;
};

const useStyles = makeStyles(styles);

export default function PopularArticleCard(props: Props) {
  const { article } = props;
  const classes = useStyles();

  let ref = React.useRef(null);

  const Sp = (
    <Grid item xs={12} style={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <a
            href={`/articles/${article.slug}`}
            ref={ref}
            style={{
              display: "block",
              overflow: "hidden",
              position: "relative",
              paddingBottom: "56.25%",
            }}
          >
            <img
              loading="lazy"
              className={classes.imgCardTop}
              src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
              alt={article.title}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </a>
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <a href={`/articles/${article.slug}`}>
            <Typography
              className={classes.cardTitle}
              style={{ height: "3rem" }}
            >
              {article.title}
            </Typography>
          </a>

          {article.category && (
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonBase
                style={{ width: "100px" }}
                onClick={() => navigate(`/categories/${article.category.slug}`)}
              >
                <CategoryReact>{article.category.name}</CategoryReact>
              </ButtonBase>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ResponsiveComponent
      sp={Sp}
      pc={
        <Grid item xs={3} style={{ flexGrow: 1 }}>
          <div>
            <a
              href={`/articles/${article.slug}`}
              ref={ref}
              style={{
                display: "block",
                overflow: "hidden",
                position: "relative",
                paddingBottom: "56.25%",
              }}
            >
              <LazyLoadImage
                placeholderSrc="/images/placeholder.png"
                className={classes.imgCardTop}
                src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
                effect="blur"
                alt={article.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </a>

            <Box>
              <a href={`/articles/${article.slug}`}>
                <h4 className={classes.cardTitle} style={{ height: "4rem" }}>
                  {article.title}
                </h4>
              </a>

              {article.category && (
                <Box
                  display="flex"
                  marginBottom={2}
                  style={{
                    justifyContent: "flex-end",
                    paddingRight: "0.5rem",
                  }}
                >
                  <ButtonBase
                    style={{ width: "100px" }}
                    onClick={() =>
                      navigate(`/categories/${article.category.slug}`)
                    }
                  >
                    <CategoryReact>{article.category.name}</CategoryReact>
                  </ButtonBase>
                </Box>
              )}
            </Box>
          </div>
        </Grid>
      }
    />
  );
}

PopularArticleCard.defaultProps = {
  showCategory: false,
};
