import React from "react";

import Header from "components/uikit/Header/Header";
import { Box, CircularProgress, NoSsr, Typography } from "@material-ui/core";
import PickupArticles from "components/organisims/articles/PickupArticles";
import Container from "components/atoms/Container";
import { Article } from "API";
import { API, graphqlOperation } from "aws-amplify";
import { searchArticles } from "graphql/queries";
import { useLocation } from "@reach/router";

type Props = {
  listArticlePickups: Article[];
};

export default function SearchResult(props: Props) {
  const { listArticlePickups } = props;

  const [articles, setArticles] = React.useState<Article[]>([]);
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();
  const url = new URL(location.href ?? "");
  const _keyword = new URLSearchParams(url.search).get("keyword");

  const get = async (keyword: string) => {
    let articles = [] as Article[];
    //全角を半角に変換
    const keywordArray = keyword.replace(/　/g, " ").split(" ");

    if (keywordArray.length > 0) {
      const conditions = keywordArray.map((myKeyword) => {
        return {
          or: [
            { title: { matchPhrase: myKeyword } },
            { title: { exists: myKeyword } },
            { title: { wildcard: "*" + myKeyword + "*" } },
            { description: { matchPhrase: myKeyword } },
            { description: { exists: myKeyword } },
            { description: { wildcard: "*" + myKeyword + "*" } },
          ],
        };
      });

      try {
        const ret = await API.graphql(
          graphqlOperation(searchArticles, {
            filter: {
              and: conditions,
              status: { eq: "publish" },
              startDateTime: { lte: new Date().toISOString() },
            },
            limit: 1000,
          })
        );

        let myNestToken = ret?.data?.searchArticles?.nextToken;
        articles = ret?.data?.searchArticles?.items;

        while (myNestToken) {
          const ret = await API.graphql(
            graphqlOperation(searchArticles, {
              filter: {
                and: conditions,
                status: { eq: "publish" },
                startDateTime: { lte: new Date().toISOString() },
              },
              nextToken: myNestToken,
            })
          );

          myNestToken = ret?.data?.searchArticles?.nextToken;
          articles = articles.concat(ret?.data?.searchArticles?.items);
        }

        setArticles(articles);
        setLoading(false);
        return;
      } catch (e) {
        console.error(e);
      }
    }
  };

  React.useEffect(() => {
    if (_keyword) {
      get(_keyword);
    }
  }, [_keyword]);

  return (
    <>
      <Header
        color="white"
        brand="Kazi ONLINE"
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "white",
        }}
      />

      <Container
        style={{
          paddingTop: "76px",
          marginTop: "100px",
          boxShadow: "none",
        }}
      >
        {loading && (
          <Box
            style={{
              height: "160px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <NoSsr>
            <div>
              {!articles.length && (
                <Box
                  style={{
                    height: "160px",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    キーワードに一致する記事が見つかりませんでした。
                  </Typography>
                </Box>
              )}

              {!articles.length && (
                <>
                  <div style={{ textAlign: "center", margin: "32px" }}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                      おすすめの記事
                    </Typography>
                  </div>

                  <PickupArticles listArticlePickups={listArticlePickups} />
                </>
              )}

              {articles.length ? (
                <>
                  <div style={{ textAlign: "center", marginBottom: "32px" }}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                      検索結果
                    </Typography>
                  </div>

                  <PickupArticles
                    articles={articles}
                    listArticlePickups={listArticlePickups}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </NoSsr>
        )}
      </Container>
    </>
  );
}
