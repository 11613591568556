import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import { cardTitle } from "assets/jss/material-kit-react.js";
import ArticleDate from "components/atoms/ArticleDate";
import Html2Text from "components/atoms/Html2Text";
import { getCfUrl } from "settings/storageSettings";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Article } from "API";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import HomeHtml2Text from "./HomeHtml2Text";
import theme from "theme";

const styles = {
  ...imagesStyles,
  cardTitle: {
    ...cardTitle,
    fontSize: "21px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  root: {
    width: "100%",
  },
  descriptionText: {
    height: "2rem",
    textOverflow: "ellipsis",
    lineHeight: 2,
  },
  category: {
    height: "1rem",
  },
  imageList: {
    minWidth: "145px",
  },
  textMuted: {
    color: "#6c757d",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
};

type Props = {
  article: Article;
  isMainArticle?: boolean;
};

const useStyles = makeStyles(styles);

export default function HomeArticleCard(props: Props) {
  const { article, isMainArticle = false } = props;
  const classes = useStyles();

  let ref = React.useRef(null);

  const isMobile = true;

  return (
    <>
      {!isMainArticle && isMobile ? (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <a
              href={`/articles/${article.slug}`}
              ref={ref}
              style={{
                display: "block",
                overflow: "hidden",
                position: "relative",
                paddingBottom: "56.25%",
              }}
            >
              <img
                loading="lazy"
                className={classes.imageList}
                src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
                alt={article.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </a>
          </Grid>

          <Grid item xs={6} style={{ marginTop: "-14px" }}>
            <a href={`/articles/${article.slug}`}>
              <h4 className={classes.cardTitle}>{article.title}</h4>
            </a>
            {article.startDateTime && (
              <p
                className={classes.textMuted}
                style={{
                  paddingTop: isMainArticle ? "4.5rem" : "0.5rem",
                }}
              >
                {article.startDateTime && (
                  <ArticleDate date={article.startDateTime} />
                )}
              </p>
            )}
          </Grid>
        </Grid>
      ) : (
        <div className={classes.root}>
          <Box>
            <a
              href={`/articles/${article.slug}`}
              ref={ref}
              style={{
                display: "block",
                paddingRight: "12px",
                position: "relative",
                paddingBottom: "56.25%",
              }}
            >
              <img
                loading="lazy"
                className={classes.imgCardTop}
                src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
                alt={article.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </a>
          </Box>

          <Box style={isMobile ? { padding: "12px" } : {}}>
            <a href={`/articles/${article.slug}`}>
              <h4
                className={classes.cardTitle}
                style={{
                  height: "5rem",
                  fontSize: isMainArticle ? "20px" : "15px",
                }}
              >
                {article.title}
              </h4>
            </a>

            {isMainArticle && (
              <p className={classes.descriptionText}>
                <HomeHtml2Text value={article.description} />
              </p>
            )}

            {article.startDateTime && (
              <p
                className={classes.textMuted}
                style={{
                  paddingTop: isMainArticle ? "2.5rem" : "0rem",
                }}
              >
                {article.startDateTime && (
                  <ArticleDate date={article.startDateTime} />
                )}
              </p>
            )}
          </Box>
        </div>
      )}
    </>
  );
}

HomeArticleCard.defaultProps = {
  showCategory: false,
};
