import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ArticleCards from "components/molecures/ArticleCards";
import { Article, UpdateCategoryInput as Category } from "API";
import HomeArticleCards from "components/molecures/HomeArticleCards";
import PopularArticleCards from "components/molecures/PopularArticleCards";

type Props = {
  articles?: Article[];
  listArticlePickups?: Article[];
  category?: Category;
  type?: "homeTop" | "homePopular";
};

const defaultProps = {
  limit: 6,
};

export default function PickupArticles(props: Props) {
  const { articles, listArticlePickups, category, type } = props;

  return (
    <>
      <div style={type === "homeTop" ? { paddingTop: "150px" } : {}}>
        <>
          {type === "homeTop" && (articles?.length ?? 0 > 3) && (
            <HomeArticleCards articles={articles ?? []} />
          )}
        </>
      </div>
      <div>
        {type === "homePopular" && listArticlePickups && (
          <PopularArticleCards articles={listArticlePickups} />
        )}
      </div>
      {type !== "homeTop" && type !== "homePopular" && (
        <ArticleCards
          articles={articles || listArticlePickups}
          showCategory={category ? false : true}
        />
      )}
    </>
  );
}

PickupArticles.defaultProps = defaultProps;
