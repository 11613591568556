import React from "react";
import { Grid } from "@material-ui/core";
import { Article } from "API";
import PopularArticleCard from "components/atoms/PopularArticleCard";

type Props = {
  articles: Article[];
};

export default function PopularArticleCards(props: Props) {
  const { articles } = props;

  const popularArticles = articles.slice(0, 4);

  return (
    <>
      {popularArticles && (
        <Grid container md={12} spacing={2}>
          {popularArticles
            .filter((article) => article)
            .map((article: Article, key) => (
              <PopularArticleCard key={key} article={article} />
            ))}
        </Grid>
      )}
    </>
  );
}

PopularArticleCards.defaultProps = {
  showCategory: false,
};
